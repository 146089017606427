<template>
  <container class="p-1">
    <dx-grid-with-search
      class="col"
      title="Prefix Maintenance"
      gridName="prefixes"
      :columns="columns"
      :dataSource="prefixStore"
      :toolbar="toolbar"
      @initialized="initializeGrid"
    >
      <template v-slot:extraActions>
        <add-button @click="handleAddPrefix" />
      </template>
      <template v-slot:actions="{ data }">
        <div>
          <icon-button
            class="m-auto text-primary pointer p-0"
            :icon="'pen-alt'"
            v-show="permissions.PrefixCreateEdit"
            @click="handleEditPrefix(data)"
            v-tooltip.left="'Edit Prefix'"
          />
          <icon-button
            type="button"
            class="btn p-0 text-primary mx-1"
            v-tooltip.left="'Clone this prefix.'"
            @click="clonePrefix(data)"
            :data-testid="`clone-prefix-${data.rowIndex}`"
            icon="copy"
          />
        </div>
      </template>
      <template v-slot:expirationCell="{ data }">
        <ExpiryTemplate :expiry-date="data.value" />
      </template>
    </dx-grid-with-search>
    <modal :status="isModalOpen" @close="handleClose">
      <prefix-maintenance-form
        :prefixId="prefixId"
        @submit="handleSubmit"
        @close="handleClose"
        :prefixToClone="prefixToClone"
      />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../common/DxGridWithSearch.vue";
import PrefixService from "../../services/prefix";
import { mapGetters, mapState } from "vuex";
import AddButton from "../common/AddButton.vue";
import auditLog from "../../services/AuditLog";
import { createLogItem } from "../../modules/helpers";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import Container from "../common/Container.vue";
import Modal from "../common/Modal.vue";
import DataSource from "devextreme/data/data_source";
import PrefixMaintenanceForm from "../forms/CodeMaintenance/PrefixMaintenanceForm.vue";
import IconButton from "../common/IconButton.vue";
import { AccessionNumberingTypeEnum } from "@/modules/enums";

export default {
  components: {
    DxGridWithSearch,
    AddButton,
    ExpiryTemplate,
    Container,
    Modal,
    PrefixMaintenanceForm,
    IconButton
  },
  metaInfo: {
    title: "Prefix Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Prefix page."
    });
  },
  data: () => ({
    grid: {},
    prefixStore: new DataSource({ store: PrefixService.searchStore }),
    isModalOpen: false,
    prefixId: null,
    availableLabs: [],
    isLoading: false,
    prefixToClone: {}
  }),

  computed: {
    ...mapState(["currentUser", "currentLab", "labSettings"]),
    ...mapGetters(["permissions"]),
    fixedFilter() {
      return [
        {
          column: "labId",
          operator: "=",
          value: this.currentLab
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions",
            visible: this.permissions.PrefixCreateEdit
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    columns() {
      const isPathologyNumbering =
        this.labSettings.AccessionNumberingType === AccessionNumberingTypeEnum.Pathology;
      return [
        {
          dataField: "prefixType.displayName",
          caption: "Type"
        },
        {
          dataField: "code"
        },
        {
          dataField: "group",
          visible: !isPathologyNumbering
        },
        {
          dataField: "description"
        },
        {
          dataField: "isBillingEnabled",
          caption: "Billing Enabled",
          dataType: "string",
          alignment: "center",
          calculateDisplayValue(option) {
            return option.isBillingEnabled ? "Yes" : "No";
          },
          headerFilter: {
            dataSource: [
              {
                text: "Yes",
                value: ["isBillingEnabled", "=", true]
              },
              {
                text: "No",
                value: ["isBillingEnabled", "=", false]
              }
            ]
          }
        },
        {
          dataField: "isBillingElectronic",
          alignment: "center",
          dataType: "string",
          calculateDisplayValue(option) {
            return option.isBillingEnabled ? "Yes" : "No";
          },
          headerFilter: {
            dataSource: [
              {
                text: "Yes",
                value: ["isBillingElectronic", "=", true]
              },
              {
                text: "No",
                value: ["isBillingElectronic", "=", false]
              }
            ]
          }
        },
        {
          dataField: "billingFixedCPT.displayName",
          caption: "CPT"
        },
        {
          dataField: "effectiveOn",
          dataType: "date"
        },
        {
          dataField: "expiryOn",
          dataType: "date",
          cellTemplate: "expirationCell"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    }
  },

  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    handleEditPrefix(data) {
      if (data) {
        this.prefixId = data.key;
      }
      this.isModalOpen = true;
    },
    handleAddPrefix() {
      this.prefixId = null;
      this.isModalOpen = true;
    },
    handleClose() {
      this.prefixId = null;
      this.isModalOpen = false;
      this.prefixToClone = {};
    },
    handleSubmit() {
      this.prefixId = null;
      this.isModalOpen = false;
      this.prefixStore.reload();
    },
    clonePrefix({ data }) {
      this.isModalOpen = true;
      this.prefixToClone = data;
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
