<template>
  <div class="p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h4>Slide Prep</h4>
    </div>
    <checkbox
      class="d-flex align-items-start mb-3"
      label="Slide Prep Only"
      id="slidePreps"
      v-model="value.isSlidePrepsOnly"
      :display="true"
    />
    <div v-show="value.isSlidePrepsOnly">
      <div class="row">
        <select-input
          label="Completion Mode"
          name="completionMode"
          class="col"
          :items="completionModes"
          v-model="prefix.caseCompletionModeId"
        />
        <div class="col d-flex pl-0">
          <select-input
            class="col"
            label="Pathologist Required"
            name="pathologistRequired"
            v-show="this.value.caseCompletionModeId === CaseCompletionModes.Specimen"
            :items="booleanOptions"
            v-model="prefix.pathologistIsRequired"
          />
          <select-input
            class="col"
            name="autoGeneratePDF"
            label="Auto Generate PDF"
            :items="booleanOptions"
            v-model="prefix.generatePdf"
          />
        </div>
        <select-input
          class="col"
          label="Gross Required"
          name="grossRequired"
          :items="booleanOptions"
          v-model="prefix.grossIsRequired"
        />
      </div>

      <div class="row">
        <select-input
          class="col"
          :dataSource="resultsMacros"
          displayExpr="macroName"
          valueExpr="macroId"
          label="Default Results Macro"
          v-model="prefix.resultsMacroId"
          :validator="$v.prefix.resultsMacroId"
          name="macro"
        />
        <select-input
          class="col"
          name="pathologist"
          label="Default Pathologist"
          :dataSource="pathologistOptions"
          v-model="prefix.pathologistId"
          :validator="$v.prefix.pathologistId"
        />
        <text-input class="col" label="Default Specimen Site" name="site" v-model="prefix.site" />

        <div class="d-flex col align-items-end">
          <select-input
            :dataSource="protocolOptions"
            label="Default Protocol"
            name="protocol"
            displayExpr="macroName"
            valueExpr="macroId"
            v-model="prefix.protocolId"
          />
          <div class="d-flex flex-column ml-auto">
            <span class="font-weight-bold" v-if="blockCount"># Of Blocks {{ blockCount }}</span>
            <span class="font-weight-bold" v-if="slideCount"># Of Slides {{ slideCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <email-table v-model="prefix.emails" />
      <fax-table v-model="prefix.faxMachines" />
      <HL7Table v-model="prefix.hL7Configs" />
      <PDFDropTable v-model="prefix.pdfFileDrops" />
      <CustomFields customFieldType="prefix" v-model="prefix.customFields" />
    </div>
  </div>
</template>

<script>
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import { booleanLookup, dateRangeFilter } from "@/modules/helpers";
import { DropdownApi, MacrosApi } from "@/services";
import { mapState } from "vuex";
import DataSource from "devextreme/data/data_source";
import EmailTable from "./EmailTable.vue";
import FaxTable from "./FaxTable.vue";
import HL7Table from "./HL7Table.vue";
import PDFDropTable from "./PDFDropTable.vue";
import { helpers } from "vuelidate/lib/validators";
import { CaseCompletionModes } from "@/modules/enums";
import Checkbox from "@/components/common/Checkbox.vue";
import CustomFields from "./CustomFieldsTable.vue";
export default {
  components: {
    SelectInput,
    TextInput,
    EmailTable,
    FaxTable,
    HL7Table,
    PDFDropTable,
    Checkbox,
    CustomFields
  },
  props: ["value"],
  name: "PrefixCompletetionModeForm",
  data() {
    return {
      completionModes: [
        {
          id: 0,
          displayName: "None"
        },
        {
          id: 1,
          displayName: "Accessioning Complete"
        },
        {
          id: 2,
          displayName: "Specimen Complete"
        }
      ],
      booleanOptions: booleanLookup.dataSource,
      pathologistOptions: DropdownApi.searchPathologists,
      blockCount: 0,
      CaseCompletionModes,
      slideCount: 0,
      customFields: []
    };
  },

  watch: {
    "prefix.protocolId": {
      immediate: true,
      handler(protocolId) {
        if (protocolId) {
          this.loadSlideBlockCount(protocolId);
        }
      }
    }
  },
  validations() {
    return {
      prefix: {
        resultsMacroId: {
          required: value => (this.value.caseCompletionModeId ? helpers.req(value) : true)
        },
        pathologistId: {
          required: value =>
            this.value.caseCompletionModeId === CaseCompletionModes.Accession ||
            (this.value.caseCompletionModeId === CaseCompletionModes.Specimen &&
              !this.prefix.pathologistIsRequired)
              ? helpers.req(value)
              : true
        }
      }
    };
  },
  methods: {
    async loadSlideBlockCount(protocolId) {
      const targetProtocol = await MacrosApi.searchStore.byKey(protocolId);
      if (targetProtocol?.procedures) {
        const calculation = targetProtocol.procedures.reduce(
          (acc, procedure) => {
            if (procedure.numberOfSlides) {
              acc.slides = acc.slides + procedure.numberOfSlides;
            }
            if (acc.blocks.length === 0) {
              acc.blocks.push(procedure.blockNum);
            } else if (!acc.blocks.includes(procedure.blockNum)) {
              acc.blocks.push(procedure.blockNum);
            }
            return acc;
          },
          { slides: 0, blocks: [] }
        );
        this.slideCount = calculation.slides;
        this.blockCount = calculation.blocks.length;
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    macroSource() {
      return MacrosApi.searchStore;
    },
    protocolOptions() {
      return new DataSource({
        store: MacrosApi.searchStore,
        paginate: true,
        filter: [["macroType", 3], "and", dateRangeFilter("effectiveOn", "expiresOn")],
        sort: ["macroName"]
      });
    },
    resultsMacros() {
      return new DataSource({
        store: MacrosApi.searchStore,
        key: "macroId",
        paginate: true,
        filter: [["macroType", 0], "and", dateRangeFilter("effectiveOn", "expiresOn")],
        sort: ["macroName"]
      });
    },
    prefix: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
